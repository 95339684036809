<template>
	<el-dialog class="dialog" :title="dialogObj.title" :visible.sync="visiable" width="50%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px">
				
				<el-row >
					<el-col :span="24">
						<el-form-item label="名字:">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="24">
						<el-form-item label="排序:">
							<el-input v-model="formData.sort"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="图标:">
							<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload" :http-request="Upload">
								<el-image v-if="formData.image" :src="photoURL + formData.image" fit="fill" class="avatar"></el-image>
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
				
			</el-form>
			<div class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">提交</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				clInitData: [],
				clInitValue: [],
				formData: {},
				row: this.dialogObj.row,
				ent: {},
				unit_type: [],
				loading: false,
				newFile: new FormData()
			};
		},
		props: {},
		watch: {
			isShow(val) {
				if (val) {
					this.open();
					this.init();
				}
			}
		},
		methods: {
			//上传前校验
			BeforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 10;
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
					this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
				} else if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				} else {
					this.currentFileName = file.name;
					if (file) {
						if(this.newFile.has("file")){
							this.newFile.delete("file");
						}
						this.newFile.append('file', file);
						return true;
					} else {
						return false;
					}
				}
				return false;
			},
			//手动上传
			Upload(param) {
				this.loading = true;
				this.$upload(this.newFile)
					.then(res => {
						if (res.code == 1000) {
							this.formData.image = res.data;
							this.loading = false;
							this.newFile = new FormData();
							this.$message.success(res.msg);
						} else {
							this.$message.error(res.msg);
						}
					})
					.catch(error => {
						this.$message.error(error);
					});
			},
			init() {
				this.row = this.dialogObj.row;
				if (this.dialogObj.openType == "insert") {
					this.formData.pid=this.row.id;
				} else if (this.dialogObj.openType == "edit") {
					this.loading = true;
					if (this.row.id) {
						this.$get("/shop-api/category/mgmt/get?id=" + this.row.id,).then((res) => {
							if (res.code == 1000) {
								this.formData = res.data
								this.loading = false;
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				} 
			},
			//打开弹窗
			open() {
				this.formData = {};
			},
			submit() {
				this.loading = true;
				if (this.dialogObj.openType == "insert") {
					this.$postJson("/shop-api/category/mgmt/save", this.formData).then((res) => {
						if (res.code == 1000) {
							this.close();
							this.$message.success(res.msg);
							//刷新表格
							this.$parent.list();
						} else {
							this.close();
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.openType == "edit") {
					this.$postJson("/shop-api/category/mgmt/update", this.formData).then((res) => {
						if (res.code == 1000) {
							this.close();
							this.$message.success(res.msg);
							//刷新表格
							this.$parent.list();
						} else {
							this.close();
							this.$message.error(res.msg);
						}
					});
				} 
			},
			//关闭弹窗
			close() {
				this.loading = false;
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.el-dialog {
		min-width: 600px;
	}
	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	::v-deep.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		line-height: 78px;
		text-align: center;
	}
	.avatar {
		width: 78px;
		height: 78px;
		display: block;
	}
</style>
