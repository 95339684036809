<template>
	<div class="equipmentLine">
		<el-table
			ref="table"
			:data="tableData"
			lazy
			style="width: 100%; margin-bottom: 20px"
			row-key="id"
			border
			:tree-props="{ children: 'child' }"
		>
			<el-table-column prop="name" label="设备树" min-width="30%">
				<template slot-scope="scope">
					<span class="equTree-span">{{ scope.row.name }}</span>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="type" label="图标" min-width="7%">
				<template slot-scope="scope">
					<img :src="photoURLs + scope.row.image" alt="" style="height: 50px; margin-top: 10px;" />
				</template>
			</el-table-column>
			<el-table-column align="center" prop="sort" label="排序" min-width="7%"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" min-width="20%" style="padding:0">
				<template slot-scope="scope">
					<div class="operationBtn">
						<el-button type="text" size="medium" v-if="scope.row.level<2" @click="insert('添加下级', scope.row, 10)">添加下级</el-button>
						<el-button type="text" size="medium" @click="edit('编辑', scope.row)">编辑</el-button>
						<el-button type="text" size="medium" v-if="scope.row.level!=0" @click="deleteOne('删除', scope.row)">删除</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>

<script>
import edit from './edit.vue';
export default {
	data() {
		return {
			originData: [],
			tableData: [],
			showEditDialog: false,
			editObj: {},
			ents: [],
			currentEnt: {},
			tableDataCopy: [],
			photoURLs:''
		};
	},
	components: {
		edit
	},
	methods: {
		insert(title, row, type) {
			this.editObj = {
				openType: 'insert',
				title: title,
				row: row,
				type: type,
			};
			this.showEditDialog = true;
		},
		edit(title, row, type) {
			this.editObj = {
				openType: 'edit',
				title: title,
				row: row,
			};
			if (type) {
				this.editObj.type = type;
			}
			this.showEditDialog = true;
		},

		deleteOne(title, row) {
			this.$confirm('是否删除该分类？', title, {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
					this.$post('/shop-api/category/mgmt/delete?id=' + row.id).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		},

		list() {
			this.$get('/shop-api/category/mgmt/list').then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
					// this.tableDataCopy = res.data || []; // 备份的全量数据
					// this.tableData = JSON.parse(JSON.stringify(res.data)).map(item => {
					// 	// 展示数据
					// 	// hasChildren 表示需要展示一个箭头图标
					// 	item.hasChildren = item.child && item.child.length > 0;
					// 	// 只展示一层
					// 	// 如果有child数据，会自动加载，就不是懒加载了，也可以配置tree-props里面的child为其他字段
					// 	item.child = null;
					// 	// 记住层级关系
					// 	item.idList = [item.id];
					// 	return item;
					// });

				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 展开
		load(tree, treeNode, resolve) {
			// 层级关系备份
			const idCopy = JSON.parse(JSON.stringify(tree.idList));

			// 查找下一层数据
			let resolveArr = this.tableDataCopy;
			let id;
			// eslint-disable-next-line
			while ((id = tree.idList.shift())) {
				const tarItem = resolveArr.find(item => item.id === id);
				tarItem.loadedchildren = true;
				resolveArr = tarItem.child;
			}

			// 处理下一层数据的属性
			resolveArr = JSON.parse(JSON.stringify(resolveArr));
			resolveArr.forEach(item => {
				item.hasChildren = item.child && item.child.length > 0;
				item.child = null;
				// 此处需要深拷贝，以防各个item的idList混乱
				item.idList = JSON.parse(JSON.stringify(idCopy));
				item.idList.push(item.id);
			});

			// 标识已经加载子节点
			tree.loadedchildren = true;

			// 渲染子节点
			resolve(resolveArr);
		},
		unload() {
			this.showTable = false;
			// eslint-disable-next-line
			this.$nextTick(() => (this.showTable = true));
			this.tableData = JSON.parse(JSON.stringify(this.tableDataCopy)).map(item => {
				// hasChildren 表示需要展示一个箭头图标
				item.hasChildren = item.child && item.child.length > 0;
				// 只展示一层
				item.child = null;
				// 记住层级关系
				item.idList = [item.id];
				return item;
			});
		}
	},
	mounted() {
		this.photoURLs = this.photoURL;
		this.list();
	}
};
</script>

<style lang="scss" scoped>
@import '@style/userCenter.scss';

.equipmentLine {
	background-color: #f5f5f5;
}

.operationBtn {
	line-height: 33px;
}

.equTree-img {
	height: 16px;
	width: 16px;
	vertical-align: middle;
	margin-right: 3px;
}

.equTree-span {
	vertical-align: middle;
}
</style>
